.main {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 1000;
}

.setQuality {
    position: absolute;
    z-index: 5000;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: black;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.qualityText {
    color: aliceblue;
    left: 0%;
    right: 0%;
    text-align: center;
    font-family: 'AvenirLTStdBlack';
}

.video {
    z-index: 400;
    display: none;
}

.fadePanel {
    z-index: 1;
    position: absolute;
    background-color: black;
    height: 100%;
    width: 100%;
    margin: 0;
    padding: 0;
    opacity: 0;
    display: block;
}

button:hover {
    color: rgb(194, 194, 194);
}

button:active {
    color: rgb(149, 149, 149);
}

.btnReload {
    background-image: url("/public/icons/Choice_button_line.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    font-size: 2rem;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    background-color: transparent;
    border: 0;
    color: aliceblue;
    padding: 1rem;
    height: 6rem;
    width: 18rem;
    font-family: 'AvenirLTStdLight';
}

.btnPlay {
    text-align: center;
    font-family: 'AvenirLTStdBlack';
    cursor: pointer;
    padding-inline: 50px;
    padding-block: 40px;
    background-color: transparent;
    font-size: 2rem;
    text-align: center;
    color: white;
    border-radius: 50%;
    display: inline-block;
    font-size: 3rem;
    border-width: 5px;
    border-color: white;
}

.btnReplay {
    left: -49.5vw;
    bottom: -49vh;
    width: 25px;
    height: 25px;
    position: fixed;
    background: url("/public/icons/replay.png") no-repeat;
    cursor: pointer;
    border: none;
    background-size: contain;
    background-position: center;
}

.btnFullScreen {
    right: -49.5vw;
    bottom: -49vh;
    width: 25px;
    height: 25px;
    position: fixed;
    background: url("/public/icons/fullscreen.png") no-repeat;
    cursor: pointer;
    border: none;
    background-size: contain;
    background-position: center;
}

.btnExitFullScreen {
    right: -49.5vw;
    bottom: -49vh;
    width: 25px;
    height: 25px;
    position: fixed;
    background: url("/public/icons/quit_fullscreen.png") no-repeat;
    cursor: pointer;
    border: none;
    background-size: contain;
    background-position: center;
}

.choiceText {
    position: absolute;
    top: -120%;
    color: aliceblue;
    left: 0%;
    right: 0%;
    text-align: center;
    font-family: 'AvenirLTStdBlack';
}

.button_choice {
    background-image: url("/public/icons/Choice_button_line.svg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    background-color: transparent;
    border: 0;
    color: aliceblue;
    padding: 1rem;
    font-family: 'AvenirLTStdBlack';
    margin-top: 20%;
    padding: 1em;
}

.div_choice {
    margin: 0 auto;
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(2, 1fr);
    z-index: 1000;
    position: absolute;
}

@media (min-width: 200px) {
    .button_choice {
        height: 3.5rem;
        width: 10rem;
        font-size: .6rem;
    }
    .choiceText {
        margin-top: 5rem;
        font-size: 1.5rem;
    }
    .qualityText {
        font-size: 1.5rem;
    }
}

@media (min-width: 900px) {
    .button_choice {
        height: 10rem;
        width: 29rem;
        font-size: 2rem;
    }
    .choiceText {
        margin-top: 15rem;
        font-size: 3.5rem;
    }
    .qualityText {
        font-size: 3.5rem;
    }
}

@font-face {
    font-family: 'AvenirLTStdBlack';
    src: url('/public/font/AvenirLTStd-Black.otf');
}

@font-face {
    font-family: 'AvenirLTStdLight';
    src: url('/public/font/AvenirLTStd-Light.otf');
}

.loader {
    position: absolute;
    z-index: 500;
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: black;
}

.loader-text {
    color: rgb(240, 240, 240);
}

.loader-progressBarContainer {
    width: 80%;
    height: 10px;
    border: 1px solid rgb(240, 240, 240);
    ;
}

.loader-progressBarContent {
    width: 0%;
    height: 100%;
    background-color: rgb(240, 240, 240);
    transition: width 0.1s ease;
}